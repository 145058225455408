import React from 'react'
import {navigate} from 'gatsby'
import {Layout, FeaturesCard, WhyUsCard, WellnessBanner, InsurancesCarousel, MayInterestYouCards} from 'widgets'
import {SEO, Select, Input, Datepicker, IntlTelInput, Button} from 'components'
import {TravelInsuranceBanner, AirplaneSeparator} from 'images'
import {IntlTelCountry} from 'models'
import moment from 'moment'
import {DESTINIES, quitLoading, setLoading, showError} from 'utils'
import {useDispatch} from 'react-redux'
type Form = {
    name: string,
    lastname: string,
    destiny_id: string,
    since: Date | undefined,
    until: Date | undefined,
    country: null | IntlTelCountry,
    country_phone: string,
    passengers: number[],
    email: string
}
const TravelInsurance = () => {
    const dispatch = useDispatch()
    const IFormState: Form = {
        name: '',
        lastname: '',
        destiny_id: DESTINIES[0].slug,
        since: undefined,
        until: undefined,
        country: null,
        country_phone: '',
        passengers: [],
        email: ''
    }
    const [form, setForm] = React.useState<Form>(IFormState)
    const change = (key: keyof Form, value: string | Date | undefined) => {
        const _form: Form = {
            ... form,
            [key]: value
        }
        setForm(_form);
    };
    const defaultIntlClass = 'intl-tel-input border-gray form-control'
    const invalidIntlClass = defaultIntlClass  +' invalid-feedback '
    const [intlClassName, setIntlClassName] = React.useState<string>(defaultIntlClass)
    const onChangeIntl = (isCorrect: boolean, country_phone: string, country: IntlTelCountry) => {
        if(isCorrect){
            let _form: Form = {
                ... form,
                country,
                country_phone
            }
            setForm(_form)
            setIntlClassName(defaultIntlClass)
        }else{
            setIntlClassName(invalidIntlClass)
        }
    }
    const addPassenger = () => {
        let passengers = [...form.passengers]
        passengers.push(18)
        const _form = {
            ... form,
            passengers
        }
        setForm(_form)
    }
    const deleteLastPassenger = () => {
        let passengers = [...form.passengers]
        passengers.splice(passengers.length-1, 1)
        const _form = {
            ... form,
            passengers
        }
        setForm(_form)
    }
    const setPassengerAge = (value: string, i: number) => {
        let passengers = [... form.passengers]
        passengers[i] = parseInt(value)
        const _form = {
            ... form,
            passengers
        }
        setForm(_form)
    }
    const canSubmit = (): boolean => {
        for (const key in form) {
            //@ts-ignore
            if(!form[key]){
                return true
            }
        }
        return false
    }
    const submit = async () => {
        setLoading()
        try {
            const payload = {
                ... form
            }
            await dispatch({
                type: 'SET_QUOTE_REQUEST',
                payload
            })
            navigate('/buscar')
        } catch (error) {
            showError(error?.message)
        }finally{
            quitLoading()
        }
    }
    return (
        <Layout>
            <SEO
                title="Seguro de viaje"
            />
            <div className="travel-insurance">
                <div
                    className="banner"
                    style={{
                        backgroundImage: `url(${TravelInsuranceBanner})`
                    }}
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-sm-12 form-container">
                                <div className="card bg-white">
                                    <div className="container">
                                        <div className="text-center p-2">
                                            <h3 className="text-purple m-0">
                                                Cotiza, compara y compra
                                            </h3>
                                            <div className="airplane-container">
                                                <img
                                                    src={AirplaneSeparator}
                                                    alt=""
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 col-sm-12">
                                                <Input
                                                    name="name"
                                                    value={form.name}
                                                    onChange={(value: string) => change('name', value)}
                                                    placeholder="Nombres"
                                                />
                                            </div>
                                            <div className="col-md-6 col-sm-12">
                                                <Input
                                                    name="lastname"
                                                    value={form.lastname}
                                                    onChange={(value: string) => change('lastname', value)}
                                                    placeholder="Apellidos"
                                                />
                                            </div>
                                        </div>
                                        <Select
                                            name="destiny_id"
                                            onChange={(value: string) => change('destiny_id', value)}
                                            value={form.destiny_id}
                                            placeholder="Destino"
                                            options={DESTINIES.map(element => {
                                                return {
                                                    label: element.es,
                                                    value: element.slug
                                                }
                                            })}
                                        />
                                        <div className="row">
                                            <div className="col-md-6 col-sm-12">
                                                <Datepicker
                                                    minDate={moment().toDate()}
                                                    onChange={(value: Date) => change('since', value)}
                                                    placeholder="Salida"
                                                    value={form.since}
                                                />
                                            </div>
                                            <div className="col-md-6 col-sm-12">
                                                <Datepicker
                                                    minDate={form.since || moment().toDate()}
                                                    value={form.until}
                                                    onChange={(value: Date) => change('until', value)}
                                                    placeholder="Regreso"
                                                />
                                            </div>
                                            <div className="col-md-6 col-sm-12">
                                                <IntlTelInput
                                                    onChange={onChangeIntl}
                                                    className={intlClassName}
                                                />
                                            </div>
                                            <div className="col-md-6 col-sm-12">
                                                <Input
                                                    name="email"
                                                    value={form.email}
                                                    onChange={(value: string) => change('email', value)}
                                                    placeholder="Email"
                                                />
                                            </div>
                                        </div>
                                        <div className="container">
                                            <div className="w-100 text-center">
                                                <p className="text-gray">
                                                    Edades de pasajeros
                                                </p>
                                                <div className="row justify-content-center">
                                                    {
                                                        form.passengers.map((element: number, i: number) => {
                                                            return(
                                                                <div
                                                                    className="col-md-3 col-sm-3"
                                                                    key={i}
                                                                >
                                                                    <Input
                                                                        name="passenger_age"
                                                                        onChange={(value: string) => setPassengerAge(value, i)}
                                                                        value={element}
                                                                    />
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                <div className="row justify-content-center">
                                                    <div className="col-md col-sm">
                                                        <Button
                                                            disabled={form.passengers.length === 1}
                                                            className="link text-purple"
                                                            icon="minus"
                                                            onClick={() => deleteLastPassenger()}
                                                        />
                                                    </div>
                                                    <div className="col-md col-sm">
                                                        <Button
                                                            disabled={form.passengers.length === 5}
                                                            className="link text-purple"
                                                            icon="plus"
                                                            onClick={() => addPassenger()}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="submit-container">
                                            <Button
                                                disabled={canSubmit()}
                                                label="Cotizar ahora"
                                                className="pink p-1"
                                                onClick={() => submit()}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <FeaturesCard
                />
                <WhyUsCard
                />
                <WellnessBanner
                />
                <div className="home-sliders">
                    <InsurancesCarousel
                    />
                </div>
                <MayInterestYouCards
                />
            </div>
        </Layout>
    )
}
export default TravelInsurance